// App.tsx

import React, { Component } from "react";
import "./App.css";
import AppContent from "./components/AppContent";
import LandingPage from "./components/LandingPage";
import AuthStateHandler from "./auth/AuthStateHandler";
//import Header from "./Header"; // Import the Header component
import { ChakraProvider, Box, ColorModeProvider } from "@chakra-ui/react";
import { Navigate, useLocation, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AskLink from "./components/AskLink";
import Welcome from "./components/Welcome";
import OnBoardingQuestions from "./components/OnBoardingQuestions";
import { AuthProvider } from './auth/AuthContext';
import usePlausible from "./utils/usePlausible";
import Tidbits from "./components/Tidbits";
import AskFeedbackPage from "./components/AskFeedbackPage";
import AskShareLink from "./components/AskShareLink";
import Header from "./components/Header";
import FeedView from './components/FeedView';
import ItemDetail from './components/ItemDetail';
import { useAuth } from './auth/AuthContext';
import ChatInterface from './components/QA';
import Login from './components/Login';
import Notes from "./components/Notes";
import QANotesView from "./components/AskHistory";

// Type for route configurations
type RouteConfig = {
  path: string;
  element: React.ReactNode;
  requireAuth: boolean;
  isPublicOnly?: boolean;
};

// Protected Route wrapper component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { authState } = useAuth();
  const location = useLocation();

  if (!authState.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

// Public Only Route wrapper (for routes that shouldn't be accessed when authenticated)
const PublicOnlyRoute = ({ children }: { children: React.ReactNode }) => {
  const { authState } = useAuth();
  const location = useLocation();

  if (authState.isAuthenticated) {
    return <Navigate to={location.state?.from?.pathname || "/"} replace />;
  }

  return <>{children}</>;
};

// Route configuration array
const routeConfigs: RouteConfig[] = [
  // Public routes
  { path: "/", element: <FeedView snippets={[]} />, requireAuth: false },
  { path: "/item/:id", element: <ItemDetail />, requireAuth: false },
  { path: "/ask", element: <ChatInterface />, requireAuth: false },
  { path: "/login", element: <Login />, requireAuth: false },
  { path: "/notes", element: <Notes />, requireAuth: false },
  { path: "/askhistory", element: <QANotesView />, requireAuth: false },
  
  // Auth-related public-only routes
  // { path: "/login", element: <LoginView />, requireAuth: false, isPublicOnly: true },
  //{ path: "/signup", element: <SignupView />, requireAuth: false, isPublicOnly: true },
  
  // Protected routes
  // { path: "/item/:id", element: <ItemDetail />, requireAuth: false },
  // { path: "/completeDisplayName", element: <CompleteDisplayName />, requireAuth: true },
  //{ path: "/completeOnboardingQuestions", element: <CompleteOnboarding />, requireAuth: true },
  // { path: "/appcontent", element: <AppContent />, requireAuth: true },
  // { path: "/ask/:askId", element: <AskDetail />, requireAuth: true }
];

function App() {
  usePlausible();
  
  return (
    <Router>
      <AuthProvider>
        <ChakraProvider>
          <AuthStateHandler />
          <Box
            className="App__Container"
            minHeight="100vh"
            backgroundColor="#181818"
          >
            <Routes>
              {routeConfigs.map(({ path, element, requireAuth, isPublicOnly }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    requireAuth ? (
                      <ProtectedRoute>{element}</ProtectedRoute>
                    ) : isPublicOnly ? (
                      <PublicOnlyRoute>{element}</PublicOnlyRoute>
                    ) : (
                      element
                    )
                  }
                />
              ))}
              
              {/* Catch-all route for 404s */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
        </ChakraProvider>
      </AuthProvider>
    </Router>
  );
}

// Optional: AuthGuard component for handling loading states and redirects
const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { authState } = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simple timeout to prevent flash of content
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />; // Replace with your loading component
  }

  return <>{children}</>;
};

export default App;