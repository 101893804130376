// firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDcalKng_yLS9eWgiqG2ec6tU0gNGrKnIc",
    authDomain: "dots-358906.firebaseapp.com",
    projectId: "dots-358906",
    storageBucket: "dots-358906.appspot.com",
    messagingSenderId: "840393212536",
    appId: "1:840393212536:web:9d6d2f4c9fbe28aac6238d",
    measurementId: "G-VCQDJF8TTV"
  };

const app = initializeApp(firebaseConfig);

// Get Firebase Auth instance
const auth = getAuth(app);

export { app, auth };