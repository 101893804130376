import { useEffect } from 'react';

const getDomain = () => {
  return window.location.hostname;
};

const getScriptSrcForDomain = (domain) => {

  if (domain.includes('localhost')) {
    // This is for local development and testing
    return 'http://localhost:8000/js/script.local.js';
  } else {
    // This is for staging/production
    return 'https://plausible.io/js/script.js';
  }
}

const usePlausible = () => {
  useEffect(() => {
    console.log('Appending plausible script!');
  
    const domain = getDomain();
    const scriptSrc = getScriptSrcForDomain(domain);

    const script = document.createElement('script');

    script.src = scriptSrc;
    script.async = true;
    script.defer = true;
    script.setAttribute('data-domain', domain);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
};

export default usePlausible;