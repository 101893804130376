import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../auth/firebaseConfig.ts'; // Import auth instance from your config
import { useAuth } from '../auth/AuthContext'; // Update path as needed
import Cookies from 'js-cookie';
import Logo from '../../public/assets/sharedot_logo.svg';
import CustomIcon from '../../public/assets/menuButtonCircles.svg';



const Header = () => {
  const { authState, setAuthInfo } = useAuth(); // Use the auth context instead of props
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  
  const menuItems = [
    { name: 'Discover', href: '/' },
    { name: 'Ask', href: '/ask' },
    { name: 'Notes', href: '/notes' },
    { name: 'Ask History', href: '/askhistory' },
    { name: 'About', href: '/about' },
  ];

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setAuthInfo({ 
        userId: null,
        isAuthenticated: false,
        displayNameCompleted: false,
        onboardingQuestionsCompleted: false
      });
      Cookies.remove('userId');
      Cookies.remove('sessionToken');
      Cookies.remove('emailForSignIn');
      navigate('/');
      toast({
        title: "Signed out successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Sign out error:", error);
      toast({
        title: "Error signing out",
        status: "error",
        duration: 3000,
      });
    }
  };


  const SignInButton = ({ isMobile = false }) => (
    <Button
      onClick={() => navigate('/login')}
      bg="white"
      color="black"
      size={{ base: "md", md: "sm" }}
      fontWeight="medium"
      width={isMobile ? "full" : "auto"}
      _hover={{
        bg: "gray.100"
      }}
    >
      Sign In
    </Button>
  );

  const SignOutButton = ({ isMobile = false }) => (
    <Button
      onClick={handleSignOut}
      variant="outline"
      bg="black"
      color="white"
      borderColor="white"
      size={{ base: "md", md: "sm" }}
      fontWeight="medium"
      width={isMobile ? "full" : "auto"}
      _hover={{
        bg: "gray.900"
      }}
    >
      Sign Out
    </Button>
  );


  return (
    <Box 
      as="header" 
      width="100%"
      position="sticky"
      top="0"
      zIndex="sticky"
      bg="black"
    >
      <Flex
        width="100%"
        py={2}
        align="center"
        justify="space-between"
      >
        <Link to="/home">
          <img 
            src={Logo} 
            alt="SHAREDOT"
            style={{ 
              height: '18px',
              marginLeft: '16px',
              display: 'block',
            }} 
          />
        </Link>

        <Flex 
          display={{ base: 'none', md: 'flex' }} 
          gap={6}
          pr={4}
          alignItems="center"
        >
          {menuItems.map((item) => (
            <Box
              key={item.name}
              as="a"
              href={item.href}
              color="white"
              _hover={{ color: 'white' }}
              transition="colors 0.2s"
              fontFamily="Poppins, sans-serif"
              fontSize="md"
              fontWeight="semibold"
            >
              {item.name}
            </Box>
          ))}
          {authState.userId ? <SignOutButton /> : <SignInButton />}
        </Flex>

        <Box 
          display={{ base: 'block', md: 'none' }} 
          pr={4}
          height="100%"
          alignItems="center"
        >
          <Button 
            variant="ghost" 
            onClick={onDrawerOpen}
            p={0}
            _hover={{ bg: 'transparent' }}
            height="auto"
          >
            <img 
              src={CustomIcon} 
              alt="Menu"
              style={{ 
                height: '32px', 
                width: '32px',
              }} 
            />
          </Button>
        </Box>

        {/* Mobile Drawer */}
        <Drawer isOpen={isDrawerOpen} onClose={onDrawerClose} placement="right">
          <DrawerOverlay />
          <DrawerContent bg="black">
            <DrawerCloseButton color="gray.300" />
            <DrawerBody display="flex" flexDirection="column">
              <VStack spacing={4} mt={12} flex="1">
                {menuItems.map((item) => (
                  <Box
                    key={item.name}
                    as="a"
                    href={item.href}
                    fontSize="lg"
                    color="gray.300"
                    _hover={{ color: 'white' }}
                    w="100%"
                    transition="colors 0.2s"
                    fontFamily="Poppins, sans-serif"
                    fontWeight="medium"
                  >
                    {item.name}
                  </Box>
                ))}
              </VStack>
              <Box w="100%" mb={16}>
                {authState.userId ? <SignOutButton isMobile /> : <SignInButton isMobile />}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};

export default Header;