import React, { useState, useEffect, memo } from 'react';
import {
  Box,
  VStack,
  Text,
  Collapse,
  IconButton,
  Input,
  Flex,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CalendarIcon } from '@chakra-ui/icons';
import withFirebaseAuth from "../utils/with_firebase_auth";
import Markdown from "react-markdown";
import Header from './Header'; // Make sure to import Header

// Shimmer styles
const shimmerStyles = `
.shimmer-container {
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`;

const ShimmerLoadingCard = () => (
  <Box
    bg="gray.900"
    borderRadius="md"
    overflow="hidden"
    mb={2}
    className="shimmer-container"
    h="80px"
    w="100%"
  >
    <Box className="shimmer-effect" />
  </Box>
);

const HistoryCard = memo(({ question }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      position="relative"
      borderWidth="1px"
      borderRadius="10px"
      overflow="hidden"
      borderColor="white"
      bg="black"
      p={4}
      width="100%"
      maxW="800px"
      mx="auto"
      mb={3}
      userSelect="none"
      _focus={{ outline: 'none' }}
      _active={{ outline: 'none' }}
    >
      {/* Question Text */}
      <Text
        fontSize={{ base: "md", md: "lg" }}
        fontWeight="700"
        color="white"
        mb={2}
        textAlign="left"
      >
        {question.askText}
      </Text>

      {/* Truncated or Full Answer */}
      <Collapse startingHeight={60} in={isExpanded}>
        <Text
          fontSize={{ base: "sm", md: "md" }}
          color="whiteAlpha.800"
          textAlign="left"
        >
          <Markdown>{question.answerText}</Markdown>
        </Text>
      </Collapse>

      {/* Expand/Collapse Button */}
      <IconButton
        mt={2}
        icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        aria-label={isExpanded ? "Collapse Answer" : "Expand Answer"}
        size="sm"
        onClick={() => setIsExpanded(!isExpanded)}
        colorScheme="whiteAlpha"
        _focus={{ outline: 'none' }}
        _active={{ outline: 'none' }}
      />
    </Box>
  );
});

const AskHistory = () => {
  const [historyQuestions, setHistoryQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('descending'); // default to descending

  // Determine if the current view is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const fetchWithAuth = withFirebaseAuth(fetch);
        const response = await fetchWithAuth(`/api/questions`);
        
        if (response.ok) {
          const questions = await response.json();
          setHistoryQuestions(questions);
        } else {
          console.error("Failed to fetch questions");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const filteredQuestions = historyQuestions
    .filter(question =>
      question.askText.toLowerCase().includes(searchTerm.toLowerCase()) ||
      question.answerText.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (sortOrder === 'ascending') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

  return (
    <Box h="full" display="flex" flexDirection="column" bg="black" minH="100vh">
      {/* Conditionally render Header for mobile view */}
      {isMobile && <Header />}

      {/* Ask History Title */}
      <Box textAlign="center" mt={4} mb={4}>
        <Text fontSize="2xl" fontWeight="bold" color="white">
          Ask History
        </Text>
      </Box>

      {/* Search Bar and Sort Button */}
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        padding="0px"
        marginBottom="20px"
      >
        <Flex
          width="100%"
          maxW="md"
          mx="auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <Input
            type="text"
            placeholder="Search your asks"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            flex={1}
            marginRight="10px"
            backgroundColor="#303133"
            color="white"
            fontSize="1rem"
            outline="none"
            borderRadius="full"
            border="none"
          />
          <Button
            onClick={toggleSortOrder}
            backgroundColor="#303133"
            color="white"
            height="46px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{
              backgroundColor: '#303133',
              color: 'white',
            }}
          >
            <CalendarIcon marginRight="5px" />
            {sortOrder === 'ascending' ? '↑' : '↓'}
          </Button>
        </Flex>
      </Flex>

      <Box flex="1" overflowY="auto" overflowX="hidden" p={4}>
        <style>{shimmerStyles}</style>
        <VStack spacing={3} align="stretch" maxW="800px" mx="auto">
          {loading
            ? Array.from({ length: 5 }).map((_, index) => (
                <ShimmerLoadingCard key={index} />
              ))
            : filteredQuestions.map(question => (
                <HistoryCard key={question.askId} question={question} />
              ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default AskHistory;
