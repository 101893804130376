import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Text,
  Flex,
  Textarea,
  Button,
  VStack,
  Input,
  useToast,
  HStack,
  IconButton,
  useDisclosure,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spacer,
  Progress,
} from '@chakra-ui/react';
import { CalendarIcon, AddIcon } from '@chakra-ui/icons';
import withFirebaseAuth from '../utils/with_firebase_auth';
import Markdown from 'react-markdown';
import Cookies from 'js-cookie';
import Header from './Header';

const shimmerStyles = `
.shimmer-container {
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`;

const ShimmerLoadingCard = () => (
  <Box
    bg="gray.900"
    borderRadius="md"
    overflow="hidden"
    mb={2}
    className="shimmer-container"
    h="80px"
    w="100%"
  >
    <Box className="shimmer-effect" />
  </Box>
);

const NoteCard = ({ note, isSelected, onClick }) => {
  const truncateText = (text, limit) => {
    if (!text) return '';
    if (text.length <= limit) return text;
    return text.slice(0, limit) + '...';
  };

  if (note.noteId === 'add') {
    // Redesigned Add Note card with selected state
    return (
      <Box
        borderWidth="1px"
        borderStyle={isSelected ? 'solid' : 'dashed'}
        borderColor={isSelected ? 'blue.500' : 'gray.500'}
        bg={isSelected ? 'blue.900' : 'black'}
        borderRadius="10px"
        cursor="pointer"
        p={4}
        width="100%"
        mb={3}
        onClick={onClick}
        textAlign="center"
        color={isSelected ? 'white' : 'gray.500'}
      >
        <Flex direction="column" alignItems="center" justifyContent="center">
          <Text fontSize="lg">+ Add Note</Text>
        </Flex>
      </Box>
    );
  }

  const renderNoteContent = () => {
    if (note.noteType === 'ONBOARDING_QUESTION') {
      const answer = note.content.split('My Answer: ')[1];
      return truncateText(answer, 150);
    } else if (note.noteType === 'FEEDBACK') {
      const feedback = note.content.split('My Feedback: ')[1];
      return truncateText(feedback, 150);
    } else {
      return truncateText(note.content, 40);
    }
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="10px"
      overflow="hidden"
      cursor="pointer"
      borderColor={isSelected ? 'blue.500' : 'white'}
      bg={isSelected ? 'blue.900' : 'black'}
      p={4}
      width="100%"
      mb={3}
      onClick={onClick}
    >
      <Text
        fontSize={{ base: 'md', md: 'lg' }}
        fontWeight="700"
        color="white"
        mb={2}
        textAlign="left"
      >
        {renderNoteContent()}
      </Text>

      {note.noteId !== 'add' && (
        <Text
          fontSize={{ base: 'sm', md: 'sm' }}
          color="whiteAlpha.600"
          textAlign="left"
        >
          {new Date(note.updatedAt).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Text>
      )}
    </Box>
  );
};

const NotesView = () => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNote, setSelectedNote] = useState(null);
  const [noteContent, setNoteContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const userId = Cookies.get('userId');
  const textareaRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('descending'); // default to descending
  const toast = useToast();
  const [hasNoteChanged, setHasNoteChanged] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const fetchNotes = async () => {
      if (!userId) {
        console.error('User ID not found');
        return;
      }

      try {
        setLoading(true);
        const fetchWithAuth = withFirebaseAuth(fetch);
        const response = await fetchWithAuth(`/api/notes`);

        if (response.ok) {
          const data = await response.json();
          setNotes(data);
          if (!isMobile) {
            // Automatically select the "Add Note" card on initial load for desktop
            handleAddNoteClick();
          }
        } else {
          console.error('Failed to fetch notes');
        }
      } catch (error) {
        console.error('Error fetching notes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, [userId, isMobile]);

  useEffect(() => {
    if (selectedNote) {
      setNoteContent(selectedNote.content || '');
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
      setHasNoteChanged(false);
    } else {
      setNoteContent('');
    }
  }, [selectedNote]);

  const handleNoteContentChange = (e) => {
    const newValue = e.target.value.slice(0, 2000);
    setNoteContent(newValue);
    if (selectedNote && selectedNote.content !== newValue) {
      setHasNoteChanged(true);
    } else {
      setHasNoteChanged(false);
    }
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    if (isMobile) {
      onOpen();
    }
  };

  const handleAddNoteClick = () => {
    const newNote = {
      noteId: 'new',
      content: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      status: 'NEW',
      noteType: 'NOTE',
    };
    setSelectedNote(newNote);
    setNoteContent('');
    if (isMobile) {
      onOpen();
    }
  };

  const handleSaveNote = async () => {
    if (!selectedNote) return;
    setIsSaving(true);

    if (!userId) {
      console.error('User ID not found. Please log in again.');
      setIsSaving(false);
      return;
    }

    const newNoteData = {
      userId: userId,
      relatedAskId: null,
      content: noteContent,
      embeddingModel: null,
      tokenCount: null,
      tokenCost: null,
      status: 'ENABLED',
      isArchived: false,
      createDate: new Date().toISOString(),
      noteType: 'NOTE',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      const fetchWithAuth = withFirebaseAuth(fetch);
      const response = await fetchWithAuth(`/api/notes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newNoteData),
      });

      if (response.ok) {
        const responseData = await response.json();

        const addedNote = { ...newNoteData, noteId: responseData.noteId };

        setNotes((prevNotes) => [addedNote, ...prevNotes]);

        setSelectedNote(addedNote);
        setHasNoteChanged(false);

        toast({
          title: 'Note Added',
          description: 'Your note has been successfully added.',
          status: 'success',
          duration: 1500,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Added</Text>
              <Text>Your note has been successfully added.</Text>
            </Box>
          ),
        });

        if (isMobile) {
          onClose();
        }
      } else {
        console.error('Failed to add note:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateNote = async () => {
    if (!selectedNote) return;
    setIsSaving(true);

    try {
      const fetchWithAuth = withFirebaseAuth(fetch);
      const response = await fetchWithAuth(
        `/api/notes/${selectedNote.noteId}`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ content: noteContent }),
        }
      );

      if (response.ok) {
        toast({
          title: 'Note Updated',
          description: 'Your note has been updated successfully.',
          status: 'success',
          duration: 1500,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Updated</Text>
              <Text>Your note has been updated successfully.</Text>
            </Box>
          ),
        });

        setSelectedNote((prevNote) => {
          return { ...prevNote, content: noteContent };
        });

        setNotes((prevNotes) => {
          return prevNotes.map((note) =>
            note.noteId === selectedNote.noteId
              ? { ...note, content: noteContent }
              : note
          );
        });
        setHasNoteChanged(false);

        if (isMobile) {
          onClose();
        }
      } else {
        console.error('Failed to update note:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating note:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteNote = async () => {
    if (!selectedNote) return;
    setIsDeleting(true);

    try {
      const fetchWithAuth = withFirebaseAuth(fetch);
      const response = await fetchWithAuth(
        `/api/notes/${selectedNote.noteId}/disable`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ status: 'DISABLED' }),
        }
      );

      if (response.ok) {
        setNotes((prevNotes) =>
          prevNotes.filter((note) => note.noteId !== selectedNote.noteId)
        );
        setSelectedNote(null);
        setNoteContent('');

        toast({
          title: 'Note Deleted',
          description: 'The note has been deleted.',
          status: 'success',
          duration: 2000,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Deleted</Text>
              <Text>The note has been deleted.</Text>
            </Box>
          ),
        });

        if (isMobile) {
          onClose();
        }
      } else {
        console.error('Failed to delete note:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) =>
      prevOrder === 'ascending' ? 'descending' : 'ascending'
    );
  };

  const filteredNotes = notes
    .filter(
      (note) =>
        note.status === 'ENABLED' &&
        note.content &&
        note.content.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === 'ascending') {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });

  return (
    <Box h="full" display="flex" flexDirection="column" bg="black" minH="100vh">
      <Header />
      {/* Include shimmer styles */}
      <style>{shimmerStyles}</style>

      {/* Progress Bar when saving or deleting */}
      {(isSaving || isDeleting) && (
        <Progress
          size="xs"
          isIndeterminate
          sx={{
            '&': {
              backgroundColor: '#1C1C1C',
            },
            '& > div': {
              backgroundColor: '#FF0050',
            },
          }}
          position="fixed"
          top={isMobile ? '0' : '64px'}
          left="0"
          right="0"
          zIndex="1500"
        />
      )}

      {isMobile ? (
        // Mobile View
        <>
          {/* Search Bar and Sort Button */}
          <Box p={4}>
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4}
            >
              <Input
                type="text"
                placeholder="Search your notes"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                flex={1}
                marginRight="10px"
                backgroundColor="#303133"
                color="white"
                fontSize="1rem"
                outline="none"
                borderRadius="full"
                border="none"
              />
              <Button
                onClick={toggleSortOrder}
                backgroundColor="#303133"
                color="white"
                height="46px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{
                  backgroundColor: '#303133',
                  color: 'white',
                }}
              >
                <CalendarIcon marginRight="5px" />
                {sortOrder === 'ascending' ? '↑' : ' ↓'}
              </Button>
            </Flex>

            {/* Notes List */}
            {loading ? (
              <VStack spacing={3} align="stretch" flex="1">
                {[...Array(5)].map((_, index) => (
                  <ShimmerLoadingCard key={index} />
                ))}
              </VStack>
            ) : (
              <VStack spacing={3} align="stretch" flex="1">
                {filteredNotes.map((note) => (
                  <NoteCard
                    key={note.noteId}
                    note={note}
                    isSelected={
                      selectedNote && selectedNote.noteId === note.noteId
                    }
                    onClick={() => handleNoteClick(note)}
                  />
                ))}
              </VStack>
            )}
          </Box>

          {/* Floating Add Note Button */}
          <IconButton
            icon={<AddIcon />}
            colorScheme="whiteAlpha"
            aria-label="Add Note"
            position="fixed"
            bottom="20px"
            right="20px"
            borderRadius="full"
            boxSize="60px"
            onClick={handleAddNoteClick}
            zIndex="1000"
            bg="white"
            color="black"
            _hover={{ bg: 'gray.300' }}
          />

          {/* Add/Edit Note Modal */}
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent bg="black">
              <ModalHeader color="white">
                {selectedNote?.status === 'NEW' ? 'Add Note' : 'Edit Note'}
              </ModalHeader>

              {/* Progress Bar inside Modal when saving or deleting */}
              {(isSaving || isDeleting) && (
                <Progress
                  size="xs"
                  isIndeterminate
                  sx={{
                    '&': {
                      backgroundColor: '#1C1C1C',
                    },
                    '& > div': {
                      backgroundColor: '#FF0050',
                    },
                  }}
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  zIndex="1500"
                />
              )}

              <ModalBody p={4}>
                <Box position="relative" h="calc(100vh - 220px)">
                  <Textarea
                    ref={textareaRef}
                    value={noteContent}
                    onChange={handleNoteContentChange}
                    fontSize="lg"
                    color="white"
                    bg="black"
                    border="none"
                    borderRadius="10px"
                    resize="none"
                    h="100%"
                    _focus={{
                      boxShadow: 'none',
                      borderColor: 'whiteAlpha.300',
                    }}
                    maxLength={2000}
                    placeholder="Start typing your note..."
                  />
                </Box>
                {/* Character Counter below the Textarea */}
                <Flex justifyContent="flex-end" mt={2}>
                  <Text color="white" fontSize="sm">
                    {`${noteContent.length}/2000`}
                  </Text>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  bg="white"
                  color="black"
                  isLoading={isSaving}
                  onClick={
                    selectedNote?.status === 'NEW'
                      ? handleSaveNote
                      : handleUpdateNote
                  }
                  mr={2}
                  disabled={
                    isSaving || !hasNoteChanged || noteContent.trim() === ''
                  }
                  _hover={{ bg: 'gray.300' }}
                >
                  Save Note
                </Button>
                {selectedNote?.status !== 'NEW' && (
                  <Button
                    variant="outline"
                    borderColor="white"
                    color="white"
                    isLoading={isDeleting}
                    onClick={handleDeleteNote}
                    mr={2}
                    _hover={{ bg: 'whiteAlpha.200' }}
                  >
                    Delete Note
                  </Button>
                )}
                <Spacer />
                <Button variant="ghost" onClick={onClose} color="white">
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : (
        // Desktop View
        <Flex h="calc(100vh - 64px)" w="full" overflow="hidden">
          {/* Left Column: Note Content */}
          <Box
            w="80%"
            h="full"
            mr="0"
            bg="black"
            display="flex"
            flexDirection="column"
            p={4}
          >
            {selectedNote ? (
              <>
                {(selectedNote.noteType === 'NOTE' ||
                  selectedNote.status === 'NEW') && (
                  <>
                    <Box flex="1" mb={2}>
                      <Textarea
                        ref={textareaRef}
                        value={noteContent}
                        onChange={handleNoteContentChange}
                        fontSize="lg"
                        color="white"
                        bg="blackAlpha.800"
                        borderColor="whiteAlpha.300"
                        borderRadius="10px"
                        resize="none"
                        h="100%"
                        _focus={{
                          boxShadow: 'none',
                          borderColor: 'whiteAlpha.300',
                        }}
                        maxLength={2000}
                        placeholder="Start typing your note..."
                      />
                    </Box>
                    {/* Character Counter below the Textarea */}
                    <Flex justifyContent="flex-end" mb={4}>
                      <Text color="white" fontSize="sm">
                        {`${noteContent.length}/2000`}
                      </Text>
                    </Flex>
                  </>
                )}
                {selectedNote.noteType !== 'NOTE' &&
                  selectedNote.status !== 'NEW' && (
                    <Box p={4}>
                      <Markdown>{selectedNote.content}</Markdown>
                    </Box>
                  )}
                {/* Save and Delete buttons underneath input box */}
                <Flex>
                  <Button
                    bg="white"
                    color="black"
                    isLoading={isSaving}
                    onClick={
                      selectedNote.status === 'NEW'
                        ? handleSaveNote
                        : handleUpdateNote
                    }
                    mr={2}
                    disabled={
                      isSaving || !hasNoteChanged || noteContent.trim() === ''
                    }
                    _hover={{ bg: 'gray.300' }}
                  >
                    Save Note
                  </Button>
                  {selectedNote.status !== 'NEW' && (
                    <Button
                      variant="outline"
                      borderColor="white"
                      color="white"
                      isLoading={isDeleting}
                      onClick={handleDeleteNote}
                      _hover={{ bg: 'whiteAlpha.200' }}
                    >
                      Delete Note
                    </Button>
                  )}
                </Flex>
              </>
            ) : (
              <Flex h="full" alignItems="center" justifyContent="center">
                <Text color="whiteAlpha.600">
                  Select a note to view or edit
                </Text>
              </Flex>
            )}
          </Box>

          {/* Right Column: Add Note, Search Bar, and Notes List */}
          <Box
            w="28%"
            bg="black"
            h="full"
            borderWidth="1px"
            borderColor="white"
            borderRadius="10px"
            p="4"
            overflowY="auto"
            display="flex"
            flexDirection="column"
          >
            {/* Add Note Card */}
            <Box mb={4}>
              <NoteCard
                note={{ noteId: 'add' }}
                isSelected={selectedNote && selectedNote.status === 'NEW'}
                onClick={handleAddNoteClick}
              />
            </Box>

            {/* Search Bar and Sort Button */}
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              padding="0px"
              marginBottom="20px"
            >
              <Flex
                width="100%"
                maxW="md"
                mx="auto"
                alignItems="center"
                justifyContent="space-between"
              >
                <Input
                  type="text"
                  placeholder="Search your notes"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  flex={1}
                  marginRight="10px"
                  backgroundColor="#303133"
                  color="white"
                  fontSize="1rem"
                  outline="none"
                  borderRadius="full"
                  border="none"
                />
                <Button
                  onClick={toggleSortOrder}
                  backgroundColor="#303133"
                  color="white"
                  height="46px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    backgroundColor: '#303133',
                    color: 'white',
                  }}
                >
                  <CalendarIcon marginRight="5px" />
                  {sortOrder === 'ascending' ? '↑' : ' ↓'}
                </Button>
              </Flex>
            </Flex>

            {/* Notes List */}
            {loading ? (
              <VStack spacing={3} align="stretch" flex="1">
                {[...Array(5)].map((_, index) => (
                  <ShimmerLoadingCard key={index} />
                ))}
              </VStack>
            ) : (
              <VStack spacing={3} align="stretch" flex="1">
                {filteredNotes.map((note) => (
                  <NoteCard
                    key={note.noteId}
                    note={note}
                    isSelected={
                      selectedNote && selectedNote.noteId === note.noteId
                    }
                    onClick={() => handleNoteClick(note)}
                  />
                ))}
              </VStack>
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default NotesView;
