// components/FeedView.js
import React, { useEffect, useState, useCallback } from 'react';
import { 
  Box, 
  VStack, 
  HStack, 
  Button, 
  Center, 
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tag,
  Wrap,
  Input,
  TagLabel,
  TagCloseButton
} from '@chakra-ui/react';
import axios from 'axios';
import Header from './Header';
import Masonry from 'react-masonry-css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import withFirebaseAuth from '../utils/with_firebase_auth';


const CONFIG = {
  imageLoadTimeout: 2000, 
  maxRetries: 2, 
  retryDelay: 2000, 
};

const masonryStyles = `
.masonry-grid {
  display: flex;
  width: 100%;
  margin-left: -8px;
  background-color: black;
}
.masonry-grid_column {
  padding-left: 8px;
  background-clip: padding-box;
}
`;

const shimmerStyles = `
.shimmer-container {
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`;

const ShimmerLoadingCard = () => (
  <Box 
    bg="gray.900"
    borderRadius="md"
    overflow="hidden"
    mb={2}
  >
    <Box 
      className="shimmer-container" 
      h={{ base: "160px", md: "240px" }}
    >
      <Box className="shimmer-effect" />
    </Box>
    
    <VStack align="stretch" p={3} spacing={2}>
      <Box 
        className="shimmer-container" 
        h="20px" 
        w="90%"
        borderRadius="md"
      >
        <Box className="shimmer-effect" />
      </Box>
      
      <Box 
        className="shimmer-container" 
        h="16px" 
        w="70%"
        borderRadius="md"
      >
        <Box className="shimmer-effect" />
      </Box>
      
      <HStack justify="space-between" pt={1}>
        <Box 
          className="shimmer-container"
          h="16px" 
          w="40%"
          borderRadius="full"
        >
          <Box className="shimmer-effect" />
        </Box>
      </HStack>
    </VStack>
  </Box>
);

const LoadingState = () => {
  const breakpointCols = {
    default: 2,
    1100: 2,
    700: 2
  };

  return (
    <Box>
      <Header />
      <style>
        {`
          .shimmer-container {
            position: relative;
            overflow: hidden;
            background: #1a1a1a;
          }

          .shimmer-effect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.1) 50%,
              rgba(255, 255, 255, 0) 100%
            );
            animation: shimmer 1.2s infinite linear;
          }

          @keyframes shimmer {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }

          .masonry-grid {
            display: flex;
            width: 100%;
            margin-left: -8px;
            background-color: black;
          }
          .masonry-grid_column {
            padding-left: 8px;
            background-clip: padding-box;
          }
        `}
      </style>
      <Box 
        bg="black"
        px={{ 
          base: 2,
          md: 8,
          lg: 24,
          xl: 48
        }}
        py={3}
      >
        <Box maxW="1000px" mx="auto">
          <Masonry
            breakpointCols={breakpointCols}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {[...Array(6)].map((_, index) => (
              <ShimmerLoadingCard key={index} />
            ))}
          </Masonry>
        </Box>
      </Box>
    </Box>
  );
};

const FILTERED_IMAGE_DOMAINS = [
  'facebook.com',
  'instagram.com',
  'fb.com',
  'fbcdn.net',
  'academia.edu',
  'academia-photos.com',
  'library.fiveable.me',
  'stjohngrimbly.com',
  'pnas.com',
  'researchgate.net',
  'pmc.ncbi.nlm.nih.gov',
  'pubmed.ncbi.nlm.nih.gov',
  'stars.astro.illinois.edu'
];

const isBlockedDomain = (url) => {
  if (!url) return true;
  return FILTERED_IMAGE_DOMAINS.some(domain => 
    url.toLowerCase().includes(domain.toLowerCase())
  );
};

const isValidImageUrl = (url) => {
  if (!url) return false;
  
  try {
    const urlObj = new URL(url);
    const fullPath = urlObj.pathname + urlObj.search;
    const hasImageExtension = /\.(jpe?g|gif|png|webp|bmp)($|\?)/i.test(fullPath);
    const isImageHost = [
      'res.com',
      'alamy.com',
      'wp.com',
      'mdpi',
      'images',
      'img',
      'photos',
      'media'
    ].some(host => urlObj.hostname.toLowerCase().includes(host));
    
    return hasImageExtension || isImageHost;
  } catch (e) {
    return /\.(jpe?g|gif|png|webp|bmp)($|\?)/i.test(url);
  }
};

const ImagePreloader = ({ src, onLoad, onError }) => {
  useEffect(() => {
    let timeoutId;
    let retryCount = 0;
    let isActive = true;

    const loadImage = () => {
      const img = new Image();
      
      const handleLoad = () => {
        if (isActive) {
          clearTimeout(timeoutId);
          onLoad();
        }
      };

      const handleError = () => {
        clearTimeout(timeoutId);
        if (isActive) {
          if (retryCount < CONFIG.maxRetries) {
            retryCount++;
            setTimeout(loadImage, CONFIG.retryDelay);
          } else {
            onError();
          }
        }
      };

      timeoutId = setTimeout(() => {
        if (isActive) {
          if (retryCount < CONFIG.maxRetries) {
            retryCount++;
            loadImage();
          } else {
            onError();
          }
        }
      }, CONFIG.imageLoadTimeout);

      img.onload = handleLoad;
      img.onerror = handleError;
      img.src = src;
    };

    loadImage();

    return () => {
      isActive = false;
      clearTimeout(timeoutId);
    };
  }, [src, onLoad, onError]);
  
  return null;
};

const SnippetCard = ({ snippet }) => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [validImages, setValidImages] = useState([]);
  const [shouldShow, setShouldShow] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [loadAttempts, setLoadAttempts] = useState({});

  useEffect(() => {
    if (snippet.imageResults && Array.isArray(snippet.imageResults)) {
      const processedImages = snippet.imageResults
        .filter(img => {
          const imageUrl = img.original;
          const linkUrl = img.link;
          return imageUrl && linkUrl &&
                 !isBlockedDomain(imageUrl) &&
                 !isBlockedDomain(linkUrl) &&
                 isValidImageUrl(imageUrl);
        })
        .slice(0, 6)
        .map((img) => ({
          url: img.original,
          source: img.source,
          type: 'item_image',
          link: img.link,
        }));

      setValidImages(processedImages);
      setShouldShow(processedImages.length > 0);
      setIsImageLoaded(false);
      setLoadAttempts({});
    } else {
      setShouldShow(false);
    }
  }, [snippet.imageResults]);

  const handleClick = useCallback(() => {
    // Save current scroll position before navigating
    sessionStorage.setItem('feedScrollPosition', window.scrollY.toString());
    navigate(`/item/${snippet.snippetId}`);
  }, [navigate, snippet.snippetId]);

  const handleImageLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  const handleImageError = useCallback(() => {
    setLoadAttempts(prev => ({
      ...prev,
      [currentImageIndex]: (prev[currentImageIndex] || 0) + 1
    }));

    if ((loadAttempts[currentImageIndex] || 0) >= CONFIG.maxRetries) {
      if (currentImageIndex < validImages.length - 1) {
        setCurrentImageIndex(prev => prev + 1);
        setIsImageLoaded(false);
      } else {
        setShouldShow(false);
      }
    }
  }, [currentImageIndex, validImages.length, loadAttempts]);

  if (!shouldShow) {
    return null;
  }

  const currentImage = validImages[currentImageIndex];
  if (!currentImage) {
    return null;
  }

  return (
    <>
      <style>{shimmerStyles}</style>
      <Box 
        onClick={handleClick}
        cursor="pointer"
        bg="black" 
        borderRadius="md"
        overflow="hidden"
        transition="all 0.2s"
        _hover={{ transform: 'translateY(-2px)', shadow: 'sm' }}
        mb={2}
      >
        <ImagePreloader
          src={currentImage.url}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
        
        <Box 
          h={{ base: "160px", md: "240px" }}
          position="relative"
          overflow="hidden"
        >
          {!isImageLoaded ? (
            <Box className="shimmer-container" height="100%">
              <Box className="shimmer-effect" />
            </Box>
          ) : (
            <img 
              src={currentImage.url} 
              alt={snippet.title}
              loading="lazy"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          )}
        </Box>

        <VStack align="stretch" p={3} spacing={1}>
          <Box 
            color="white" 
            fontSize={{ base: "14px", md: "16px" }}
            fontWeight={{ base: "500", md: "600" }}
            noOfLines={2}
            textAlign="left"
            lineHeight="1.4"
          >
            {snippet.title}
          </Box>

          <Box 
            color="gray.400" 
            fontSize={{ base: "12px", md: "13px" }}
            noOfLines={0}
            textAlign="left"
            lineHeight="1.4"
          >
            {snippet.content}
          </Box>

          <HStack justify="space-between">
            <Box
              bg="whiteAlpha.200"
              px={2}
              py={0.5}
              borderRadius="full"
              fontSize={{ base: "10px", md: "12px" }}
              color="white"
              isTruncated
              maxW="45ch"
              textAlign="left"
            >
              {snippet.topicTitle || 'Default Topic'}
            </Box>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

const FeedView = () => {
  const navigate = useNavigate();
  const [snippets, setSnippets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Dynamic categories from interest_selector
  const [tier1Categories, setTier1Categories] = useState([]);
  // Custom interests from user_feed (type='custom')
  const [customInterests, setCustomInterests] = useState([]);
  const [newInterest, setNewInterest] = useState("");

  // Map from topicId or content to their record details
  const [interestsMap, setInterestsMap] = useState({});

  const askerUserId = Cookies.get('userId'); 

  const breakpointCols = {
    default: 2,
    1100: 2,
    700: 2
  };

   // Update the scroll position effect
   useEffect(() => {
    // Restore scroll position when component mounts
    const savedScrollPosition = sessionStorage.getItem('feedScrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }

    // Save scroll position when user scrolls
    const handleScroll = () => {
      sessionStorage.setItem('feedScrollPosition', window.scrollY.toString());
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array means this runs once on mount

  const fetchSnippets = useCallback(async () => {
    try {
      setLoading(true);
      // If user is not logged in, make a direct fetch without auth
      const isLoggedIn = Cookies.get('userId');
      let response;
      
      if (isLoggedIn) {
        const fetchWithAuth = withFirebaseAuth(fetch);
        response = await fetchWithAuth('/api/fetchFeedv2');
      } else {
        response = await fetch('/api/fetchFeedv2');
      }
      
      const data = await response.json();
      setSnippets(data);
    } catch (err) {
      console.error('Error fetching snippets:', err);
      setError('Failed to load snippets');
    } finally {
      setLoading(false);
    }
  }, []);
  
  const fetchInterestsData = useCallback(async () => {
    try {
      const isLoggedIn = Cookies.get('userId');
      let response;
      
      if (isLoggedIn) {
        const fetchWithAuth = withFirebaseAuth(fetch);
        response = await fetchWithAuth('/api/fetchInterestSelectorInterests');
      } else {
        // Regular fetch for non-authenticated users
        response = await fetch('/api/fetchInterestSelectorInterests');
      }
      
      const { tier1Categories, customInterests } = await response.json();

      // Build interestsMap
      const newMap = {};

      // For interest_selector types, use topicId as key
      for (const cat of tier1Categories) {
        for (const i of cat.interests) {
          newMap[i.topicId] = {
            id: i.id,
            type: i.type,
            isEnabled: i.isEnabled,
            content: i.topicId
          };
        }
      }

      // Only process custom interests if logged in
      if (isLoggedIn) {
        for (const c of customInterests) {
          newMap[c.content] = {
            id: c.id,
            type: c.type,
            isEnabled: c.isEnabled,
            content: c.content
          };
        }
        setCustomInterests(customInterests.map(ci => ci.content));
      } else {
        setCustomInterests([]);
      }

      setInterestsMap(newMap);
      setTier1Categories(tier1Categories);

    } catch (error) {
      console.error('Error fetching interests data:', error);
    }
  }, []);


  const updateInterestStatus = async (content, isEnabled, type, isDelete = false, existingId = null) => {
    try {
      const payload = {
        userId: askerUserId,
        content,
        isEnabled,
        type
      };
      if (existingId) payload.id = existingId;
      if (isDelete) payload.isDelete = true;

      const fetchWithAuth = withFirebaseAuth(fetch);
      const response = await fetchWithAuth('/api/updateInterestStatus', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      const updated = data.interest;
      
      setInterestsMap(prev => ({
        ...prev,
        [content]: {
          id: updated.id,
          type: updated.type,
          isEnabled: updated.isEnabled,
          content: updated.content
        }
      }));
    } catch (error) {
      console.error('Error updating interest status:', error);
    }
  };

  const toggleInterest = (key, interest, type = 'interest_selector', isCustom = false) => {
    // key = topicId for standard interests, content for custom
    // Determine current state:
    const current = interestsMap[interest];
    const currentlyEnabled = current ? current.isEnabled : false;
    const newEnabled = !currentlyEnabled;
    const existingId = current ? current.id : null;

    updateInterestStatus(interest, newEnabled, type, false, existingId);

    setInterestsMap(prev => ({
      ...prev,
      [interest]: {
        ...prev[interest],
        isEnabled: newEnabled
      }
    }));
  };

  const handleNewInterestKeyDown = (e) => {
    if (e.key === 'Enter') {
      const trimmed = newInterest.trim();
      if (trimmed.length > 0) {
        // Check if it already exists in customInterests or in tier1Categories
        const allKnownInterests = [
          ...customInterests,
          ...tier1Categories.flatMap(cat => cat.interests.map(i => i.tier2))
        ];

        const isNew = !allKnownInterests.includes(trimmed);
        if (isNew) {
          setCustomInterests(prev => [...prev, trimmed]);
        }

        // For a new custom interest, no id, create it now
        updateInterestStatus(trimmed, true, 'custom', false, null);

        setInterestsMap(prev => ({
          ...prev,
          [trimmed]: {
            type: 'custom',
            content: trimmed,
            isEnabled: true,
            id: null // will be updated once API returns
          }
        }));

        setNewInterest("");
      }
    }
  };

  const removeCustomInterest = (interest) => {
    // Mark isDelete = true for this custom interest
    const existing = interestsMap[interest];
    const existingId = existing ? existing.id : null;

    updateInterestStatus(interest, false, 'custom', true, existingId);

    setCustomInterests(prev => prev.filter(i => i !== interest));
    setInterestsMap(prev => {
      const newMap = { ...prev };
      delete newMap[interest];
      return newMap;
    });
  };

  useEffect(() => {
    fetchSnippets();
    fetchInterestsData();
  }, [fetchSnippets, fetchInterestsData]);

  // Add isLoggedIn check
  const isLoggedIn = !!Cookies.get('userId');

  const handleLoginClick = () => {
    onClose(); // Close the modal first
    navigate('/login'); // Navigate to login page
  };

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return (
      <>
        <Header />
        <Center h="100vh" color="white">
          {error}
        </Center>
      </>
    );
  }

  return (
    <Box>
      <Header />
      <style>{masonryStyles}</style>
      <Box bg="black" px={{ base: 2, md: 8, lg: 24, xl: 48 }} py={3}>
        <HStack justifyContent="flex-start" mb={4}>
          <Button 
            onClick={onOpen} 
            bg="whiteAlpha.200" 
            color="white" 
            _hover={{ bg: 'whiteAlpha.300' }}
            size="sm"
          >
            Select Interests
          </Button>
        </HStack>

        <Box maxW="1000px" mx="auto">
          <Masonry
            breakpointCols={breakpointCols}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {snippets
              .filter(snippet => snippet.imageResults?.length > 0)
              .map(snippet => (
                <SnippetCard key={snippet.snippetId} snippet={snippet} />
              ))}
          </Masonry>
        </Box>
      </Box>

      {/* Interest Selector Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent bg="black" color="white">
          <ModalHeader>What are you interested in?</ModalHeader>
          <ModalCloseButton color="white"/>
          <ModalBody>
            {/* Custom Interests Section - Only for logged in users */}
            <Box mb={6}>
              {isLoggedIn ? (
                <>
                  <Box fontWeight="600" mb={2}>Custom Interests</Box>
                  <Input 
                    placeholder="Type a custom interest and press Enter..."
                    variant="outline"
                    mb={2}
                    bg="whiteAlpha.100"
                    borderColor="whiteAlpha.300"
                    color="white"
                    _placeholder={{ color: 'whiteAlpha.500' }}
                    onKeyDown={handleNewInterestKeyDown}
                    value={newInterest}
                    onChange={(e) => setNewInterest(e.target.value)}
                  />
                  <Wrap spacing={2}>
                    {customInterests.map(interest => {
                      const data = interestsMap[interest];
                      const isSelected = data?.isEnabled || false;
                      return (
                        <Tag 
                          key={interest} 
                          size="md" 
                          cursor="pointer"
                          bg={isSelected ? "white" : "whiteAlpha.200"} 
                          color={isSelected ? "black" : "white"}
                          borderRadius="full"
                          _hover={{ bg: isSelected ? "whiteAlpha.800" : "whiteAlpha.300" }}
                          onClick={() => toggleInterest("custom", interest, 'custom', true)}
                        >
                          <TagLabel>{interest}</TagLabel>
                          <TagCloseButton 
                            onClick={(e) => {
                              e.stopPropagation();
                              removeCustomInterest(interest);
                            }} 
                          />
                        </Tag>
                      );
                    })}
                  </Wrap>
                </>
              ) : (
                <Box 
                  border="1px dashed"
                  borderColor="whiteAlpha.300"
                  borderRadius="md"
                  p={4}
                  textAlign="center"
                >
                  <Box fontSize="sm" color="whiteAlpha.800" mb={3}>
                    Want to add your own custom interests?
                  </Box>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={handleLoginClick}
                  >
                    Log in to Create Custom Interests
                  </Button>
                </Box>
              )}
            </Box>

            {/* Regular Interests Section - Always visible */}
            <Box mb={2}>
              <Box fontWeight="600" mb={2}>Popular Topics</Box>
              {tier1Categories.map(category => (
                <Box mb={4} key={category.tier1}>
                  <Box fontWeight="600" mb={2}>{category.tier1}</Box>
                  <Wrap spacing={2}>
                    {category.interests.map(i => {
                      const data = interestsMap[i.topicId];
                      const isSelected = data?.isEnabled || false;
                      return (
                        <Tag 
                          key={i.topicId} 
                          size="md" 
                          cursor="pointer"
                          bg={isSelected ? "white" : "whiteAlpha.200"} 
                          color={isSelected ? "black" : "white"}
                          onClick={() => toggleInterest(i.topicId, i.topicId, 'interest_selector', false)}
                          borderRadius="full"
                          _hover={{ bg: isSelected ? "whiteAlpha.800" : "whiteAlpha.300" }}
                        >
                          {i.tier2}
                        </Tag>
                      );
                    })}
                  </Wrap>
                </Box>
              ))}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FeedView;