import { Fetch } from "fetch-compose";
import { auth } from "../auth/firebaseConfig";

export default function withFirebaseAuth<T extends RequestInit>(
  fetch: Fetch<T>
): Fetch<T> {
  return async (req, opts) => {
    let { headers = {}, ...rest } = opts || {};

    // FIXME: This may allow for CSRF attacks. Change later.
    await auth.authStateReady();
    const idToken = await auth.currentUser?.getIdToken();
    if (!idToken) {
      throw new Error("No ID token found.");
    }

    // @ts-ignore
    return fetch(req, {
      headers: {
        ...headers,
        "X-Firebase-IdToken": `${idToken}`,
      },
      ...rest,
    });
  };
}
