import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

type AuthState = {
    isAuthenticated: boolean;
    userId: string | null;
    displayNameCompleted?: boolean;
    onboardingQuestionsCompleted?: boolean;
};

type AuthContextType = { 
    authState: AuthState;
    setAuthInfo: (userInfo: Partial<AuthState>) => void;
    checkUserOnboardingStatus: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => useContext(AuthContext) as AuthContextType;

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const navigate = useNavigate();
    const [authState, setAuthState] = useState<AuthState>({
        isAuthenticated: false,
        userId: null,
    });

    const setAuthInfo = (userInfo: Partial<AuthState>) => {
        setAuthState(prevState => ({
            ...prevState,
            ...userInfo,
            // Calculate isAuthenticated based on the presence of userId or any other logic
            isAuthenticated: userInfo.userId != null ? true : prevState.isAuthenticated,
        }));
    };

    const fetchUserDetails = async () => {
        const userId = Cookies.get('userId'); // Retrieve the User ID from cookies, which should match the backend expectation
    
        // Check if userId is present and not undefined
        if (!userId) {
            console.error("User ID not found in cookies or is undefined.");
            throw new Error("User ID not found in cookies or is undefined."); // Prevent the fetch call
        }
    
        try {
            const userDetailsResponse = await fetch(`/api/getUserDetails`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId }), // Correct payload to match backend expectation
            });
    
            if (!userDetailsResponse.ok) {
                throw new Error(`Failed to fetch user details: ${userDetailsResponse.status} ${userDetailsResponse.statusText}`);
            }
            
            const userDetails = await userDetailsResponse.json();
            console.log('User details retrieved successfully:', userDetails);
            return userDetails;
        } catch (error) {
            console.error("Error during user authentication and detail retrieval:", error.message);
            throw error; // Rethrow to handle it further up the call stack
        }
    };
    
    

    const checkUserOnboardingStatus = async () => {
       try {
           const userDetails = await fetchUserDetails();
           console.log("userDetails called", userDetails)
        
           // Only call setAuthInfo if the fetched details represent a change
           if (!userDetails.userId || authState.displayNameCompleted !== !!userDetails.displayName || authState.onboardingQuestionsCompleted !== userDetails.isOnboardingComplete) {
               setAuthInfo({
                   userId: Cookies.get('userId'), // This might be redundant if userId is already set
                   displayNameCompleted: !!userDetails.displayName, // Convert to boolean explicitly if necessary
                   onboardingQuestionsCompleted: userDetails.isOnboardingComplete,
               });
           }
       } catch (error) {
            // navigate('/completeDisplayName');
           console.error("Error fetching user details:", error);
       }
    };

    useEffect(() => {
        if (authState.userId) {
            checkUserOnboardingStatus();
        }
    }, [authState.userId]);

    return (
        <AuthContext.Provider value={{ authState, setAuthInfo }}>
            {children}
        </AuthContext.Provider>
    );
};
